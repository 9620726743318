<template>
  <div class="search-bar search-bar--negative-section-margin theme-light">
    <div class="search-bar__inner">
      <div class="search-bar__description">
        {{
          $textDictionary['productDocumentDownloadTab.searchDescription'] ?? ''
        }}
      </div>
      <div class="search-bar__input">
        <div
          v-if="!store.downloads?.filters"
          style="display: flex; gap: 8px; flex-direction: column"
        >
          <LoadingSkeleton class="search-bar-loading" />
        </div>
        <PrimeVueForm
          v-else
          :id="searchFormId"
          class="p-search-box-form"
          @submit="submitSearchForm()"
        >
          <PrimeVueIconField>
            <PrimeVueInputText
              id="search-box-value"
              v-model="store.query"
              type="search"
              rounded
              name="search-value"
              size="large"
              autocomplete="off"
              :placeholder="
                $textDictionary[
                  'productDocumentDownloadTab.searchPlaceholder'
                ] ?? ''
              "
              class="w-100 p-search-box"
              @update:model-value="clearSarchTerm()"
            >
            </PrimeVueInputText>
            <PrimeVueInputIcon @click="submitSearchForm()">
              <icon :name="'action_bar_magnifier'" size="small" color="black" />
            </PrimeVueInputIcon>
          </PrimeVueIconField>
        </PrimeVueForm>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Icon, LoadingSkeleton } from '@hypercodestudio/basler-components';

import { useDownloadStore } from '~/stores/downloadStore/downloadStore';

const { $textDictionary, $analytics } = useNuxtApp();
const route = useRoute();
const router = useRouter();
const searchFormId = useId();

const store = useDownloadStore();

onMounted(() => {
  if (route.query.query) {
    store.query = route.query.query.toString();
  }
});

function submitSearchForm() {
  store.fetchDownloads();

  router.push({
    query: {
      ...route.query,
      query: store.query
    }
  });

  $analytics?.pushToDataLayer({
    action: 'document_search',
    category: 'downloads',
    event: 'search-download',
    label: store.query
  });
}
function clearSarchTerm() {
  if (isEmpty(store.query)) {
    router.replace({
      query: store.filters,
      replace: true
    });
    store.fetchDownloads();
  }
}
</script>

<style>
.search-bar-loading {
  width: 100%;
  border-radius: 100px;
  height: 48px;
}
@media (min-width: 1024px) {
  .search-bar-loading {
    height: 88px;
  }
}
</style>
